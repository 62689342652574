<template>
	<div>
		<div class="box">
			<div id="informations" v-if="horse">
				<div class="row">
					<div class="col">
						<button v-e-online-only class="btn rounded-pill mr-2 mb-2" :class="{ 'btn-primary': edited_pedigree_file, 'btn-secondary': !edited_pedigree_file }" @click="link_pedigree_file">
							<span v-if="edited_pedigree_file">{{ $t('horse.save_pedigree_pdf') }} </span>
							<span v-else>{{ $t('horse.link_pedigree_pdf') }} </span>
							<font-awesome-icon v-if="pedigree_link_working" :icon="['fal', 'spinner']" :pulse="true" />
							<font-awesome-icon v-else :icon="['fal', 'file-spreadsheet']" />
						</button>
						<a
							v-e-online-only
							v-if="horse.horse_sire && !mask_ifce"
							class="btn btn-secondary rounded-pill mr-2 mb-2"
							:href="`https://infochevaux.ifce.fr/externe/${horse.horse_sire}`"
							target="_blank"
						>
							{{ $t('horse.voir_fiche_ifce') }}
							<font-awesome-icon :icon="['fal', 'external-link-square-alt']" />
						</a>
						<button v-e-online-only v-if="horse.horse_sire && !mask_ifce" class="btn btn-secondary rounded-pill mr-2 mb-2" @click="refresh_sire_infos">
							{{ $t('horse.actualiser_donnees') }}
							<font-awesome-icon :icon="['fal', 'sync']" :spin="sire_working" :disabled="sire_working" />
						</button>
						<button v-e-online-only class="btn btn-secondary rounded-pill mr-2 mb-2" @click="print_infos">
							{{ $t('horse.print_infos_horse') }}
							<font-awesome-icon :icon="['fal', 'file-spreadsheet']" />
						</button>
						<button v-e-online-only class="btn btn-secondary rounded-pill mr-2 mb-2" @click="merge_horse">
							{{ $t('action.merge') }}
							<font-awesome-icon :icon="['far', 'copy']" />
						</button>
						<button v-if="horse.horse_inactive" class="btn btn-secondary rounded-pill mr-2 mb-2" @click="unarchive_horse">
							{{ $t('action.unarchive') }}
						</button>
						<button v-if="hasWeatherbys" class="btn btn-secondary rounded-pill mr-2 mb-2" @click="edit_weatherbys"> 
							{{ $t('action.rapprochement_weatherbys') }} 
						</button>


						<ModalPrintInfosHorse ref="modal_print_infos_horse"/>
						<ModalSearchWeatherbys ref="modal_search_weatherbys" :horse="horse" @updated="weatherbysUpdated"/>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_nom">{{ $t('horse.form.nom') }}</label>	
							<div class="col-8"><input class="form-control" type="text" v-model="horse.horse_nom"></div>
						</div>
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_alias">{{ $t('horse.form.alias') }}</label>	
							<div class="col-8"><input type="text" class="form-control" v-model="horse.horse_alias"></div>
						</div>
					</div>
					<div class="col-md-6">						
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_sire">{{ $t("horse.numero_sire") }}</label>	
							<div class="input-group col-8" v-if="!readonly">
								<input type="text" class="form-control" :readonly="readonly || !show_actions" v-model="horse_sire_cle" v-mask="'00000000 U'">
								<div class="input-group-append" v-if="!readonly && isOkSire && show_actions">
									<button class="btn btn-primary" type="button" @click.prevent="confirm_sire"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('global.valider') }}</button>
								</div>
							</div>
							<div class="col-8" v-else>
									<e-copybox boxed :value="horse_sire_cle" removespace></e-copybox>
							</div>
						</div>
						<div class="row mt-2" v-if="!horse_francise">
							<b-alert show variant="warning" class="col-4">
								<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/> 
								{{ $t('horse.non_francise') }}
							</b-alert>
						</div>
					</div>
				</div>	
				<div class="row">
					<div class="col-md-6">
						<HorseRace v-if="horse.horse_race || !horse.horse_sire" :editable="editable && show_actions" :horse_race_code="horse.horse_race" @change="changeRace"/>
						<HorseRobe v-if="horse.horse_robe || !horse.horse_sire" :editable="show_actions" :horse_robe_code="horse.horse_robe"  @change="changeRobe"/>
						<HorseSexe v-if="horse.horse_sexe || !horse.horse_sire" :editable="editable && show_actions" :horse_sexe_code="horse.horse_sexe" @change="changeSexe"/>
						<HorseTaille v-if="horse.horse_id" :editable="show_actions" :horse_prop_taille.sync="horse.horse_taille" :horse_id="horse.horse_id" />
					</div>
					<div class="col-md-6">
						<div class="mt-2 row">
							<div class="col-4">
								<b-dropdown :text="text_ue_dropdown + ' ▾'" v-b-tooltip.hover.top :title="$t('global.tooltip_ue')">
									<b-dropdown-item :disabled="disabled_ueln" @click="text_ue_dropdown = $t('horse.numero_ueln')">
										{{ $t('horse.numero_ueln') }}
									</b-dropdown-item>
									<b-dropdown-item :disabled="disabled_uesa" @click="text_ue_dropdown = $t('horse.numero_uesa')">
										{{ $t('horse.numero_uesa') }}
									</b-dropdown-item>
								</b-dropdown>
							</div>
							<div class="col-8" >
								<div class="row no-gutters">
									<div class="col">
										<e-copybox 
											boxed
											:value.sync="horse_ue" 
											:readonly="!show_actions"
											placeholder="0000XX00000000X"
										></e-copybox>

									</div>
									<div class="col-auto" v-if="ue_state != 'initialized'">
										<b-input-group>
											<template v-slot:append>
												<b-input-group-text>
													<span>
														<font-awesome-icon v-if="ue_state === 'editing'" :icon="['fas', 'save']" v-on:click.prevent="saveHorseUe" />
														<font-awesome-icon v-else-if="ue_state === 'saving'" :icon="['fas', 'spinner']" pulse />
														<font-awesome-icon v-else-if="ue_state === 'saved'" :icon="['fal', 'check']" />
													</span>
												</b-input-group-text>
											</template>
											<!-- <b-form-input
												placeholder="0000XX00000000X"
												v-model="horse_ue"
												:readonly="!show_actions"
											/> -->
										</b-input-group>

									</div>

								</div>
							</div>
						</div>
						<HorseTranspondeur v-if="horse.horse_id" :editable="show_actions" :horse_prop_transpondeur.sync="horse.horse_transpondeur" :horse_id="horse.horse_id" @submit="refreshAfterChangeMicroship"/>

						<HorseInterne v-if="horse.horse_id" :editable="show_actions" :horse_prop_interne.sync="horse.horse_interne" :horse_id="horse.horse_id" />

						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_passeport">{{ $t('horse.form.passeport') }}</label>	
							<div class="col-8"><input class="form-control" type="text" v-model="horse.horse_passeport"></div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row mt-2">
							<label class="col-4 col-form-label"  for="horse_country" >{{ $t("horse.country") }}</label>
							<div class="col-8"><CountryInput v-model="horse.horse_country_code" @change="changeCountry"></CountryInput></div>
						</div>
						<div class="row mt-2" v-if="horse.horse_id && (horse.horse_sexe == 'M' || horse.horse_sexe == 'H')">
							<div class="col">
								<b-form-checkbox id="horse_stallion" v-model="horse.horse_stallion"><label for="horse_stallion">{{ $t("horse.male_reproducteur") }}</label></b-form-checkbox>
							</div>
						</div>
						<div class="row mt-2" v-if="hasDedicatedClientArea">
							<div class="col-12">
								<b-form-checkbox id="horse_display_ca" v-model="horse.horse_display_ca" @input="changeCaVisibility()"><label for="horse_display_ca">{{ $t("table.tiers_horse_horse.horse.set_ca_displayable") }}</label></b-form-checkbox>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_country">{{ $t('horse.lien_connexe') }}</label>
							<div class="col-8">
								<div class="input-group" >
									<input type="text" class="form-control" :readonly="!show_actions" v-model="horse.horse_francegalop">
									<div class="input-group-append" v-if="horse.horse_francegalop">
										<a :href="horse.horse_francegalop" class="input-group-text" target="_blank" rel="noopener noreferrer">
											<font-awesome-icon :icon="['fas', 'external-link']" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_naissance">{{ $t('horse.form.datenaissance') }}</label>
        					<div class="col-8">
								<input
									id="horse_naissance"
									v-if="readonly || !show_actions"
									:readonly="readonly || !show_actions"
									:value="horse_datenaissance"
									class="form-control"
									type="text"
								/>
								<e-datepicker
									v-else
									v-model="horse.horse_datenaissance"
								/>
							</div>
						</div>

						<div class="row mt-2">
							<label class="col-4 col-form-label" for="horse_datedeces">{{ $t('horse.form.datedeces') }}</label>
        					<div class="col-8">
								<input
									id="horse_datedeces"
									v-if="readonly_death || !show_actions"
									:readonly="readonly_death || !show_actions"
									:value="horse_datemort"
									class="form-control"
									type="text"
								/>
								<e-datepicker
									v-else
									v-model="horse.horse_datemort"
								/>
							</div>
						</div>

	
						<HorseCategorie ref="horse_categorie" :selected_horse_categories="horse.categories" :horse_id="horse.horse_id" :readonly="!show_actions" />


					</div>
				</div>

				<hr >
				<div class="row">
					<div class="col-md-6">
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="localisation_livret">{{ $t('horse.localisation_livret') }}</label>
        					<div class="col-8">
								<b-input-group>
									<b-form-input
										class="form-control"
										type="text"
										:placeholder="$t('horse.localisation_livret')"
										v-model="horse.horse_localisationlivret"
										@keyup="showSaveButton"
									/>
								</b-input-group>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row mt-2">
							<label class="col-4 col-form-label" for="carte">{{ $t('horse.localisation_carte') }}</label>
        					<div class="col-8">
								<b-input-group>
									<b-form-input
										id="carte"
										class="form-control"
										type="text"
										:placeholder="$t('horse.localisation_carte')"
										v-model="horse.horse_carte"
										@keyup="showSaveButton"
									/>
								</b-input-group>
							</div>
						</div>
					</div>
				</div>

		
				<div class="row mt-2">
					<label class="col-sm-2 col-form-label" for="horse_commentaire">{{ $t('horse.commentaire') }}</label>
					<div class="col-sm-10">
						<textarea class="form-control" id="horse_commentaire" :placeholder="$t('horse.commentaire')" v-model="horse.horse_commentaire" @keyup="liveCountDown"></textarea>
						<p v-if="horse.horse_commentaire">{{ $t('horse.commentaire_char') }}: <span v-bind:class="{'text-danger': error_commentaire }">{{ horse.horse_commentaire.length }} / {{ limit_commentaire }}</span></p>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col text-center" v-show="saveButton">
						<button class="btn btn-primary rounded-pill" @click='saveInfo'> {{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']" class="ml-1" /></button>
						<button class="btn btn-secondary ml-4 rounded-pill" @click='cancelInfo'> {{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/></button>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col">
						<div v-if="(horse_pedigree !== null && has_pedigree) || display_pedigree">
							<table class="genealogie">
								<tbody>
									<tr>
										<td class="gen_principal" rowspan="4">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_1.horse_id }}" v-if="horse_pedigree.pedigree_horse_1">
													{{ horse_pedigree.pedigree_horse_1.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_1 }}</span>
											</span> 
											<SearchInputPedigree v-if="edit_pedigree_bool" :sexe="1" :horse_nom.sync="horse_pedigree.pedigree_nom_1" :sire_encode.sync="horse_pedigree.pedigree_sireencode_1"/>
										</td>
										<td class="gen_secondaire" rowspan="2">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_3.horse_id }}" v-if="horse_pedigree.pedigree_horse_3">
													{{ horse_pedigree.pedigree_horse_3.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_3 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_3">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_7.horse_id }}" v-if="horse_pedigree.pedigree_horse_7">
													{{ horse_pedigree.pedigree_horse_7.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_7 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_7">
										</td>
									</tr>
									<tr>
										<td  rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_8.horse_id }}" v-if="horse_pedigree.pedigree_horse_8">
													{{ horse_pedigree.pedigree_horse_8.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_8 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_8">
										</td>
									</tr>
									<tr>
										<td  rowspan="2">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_4.horse_id }}" v-if="horse_pedigree.pedigree_horse_4">
													{{ horse_pedigree.pedigree_horse_4.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_4 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_4">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_9.horse_id }}" v-if="horse_pedigree.pedigree_horse_9">
													{{ horse_pedigree.pedigree_horse_9.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_9 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_9">
										</td>
									</tr>
									<tr>
										<td  rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_10.horse_id }}" v-if="horse_pedigree.pedigree_horse_10">
													{{ horse_pedigree.pedigree_horse_10.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_10 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_10">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="4">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_2.horse_id }}" v-if="horse_pedigree.pedigree_horse_2">
													{{ horse_pedigree.pedigree_horse_2.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_2 }}</span>
											</span> 
											<SearchInputPedigree v-if="edit_pedigree_bool" :sexe="2" :horse_nom.sync="horse_pedigree.pedigree_nom_2" :sire_encode.sync="horse_pedigree.pedigree_sireencode_2"/>
										</td>
										<td class="gen_principal" rowspan="2">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_5.horse_id }}" v-if="horse_pedigree.pedigree_horse_5">
													{{ horse_pedigree.pedigree_horse_5.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_5 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_5">										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_11.horse_id }}" v-if="horse_pedigree.pedigree_horse_11">
													{{ horse_pedigree.pedigree_horse_11.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_11 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_11">
										</td>
									</tr>
									<tr>
										<td rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_12.horse_id }}" v-if="horse_pedigree.pedigree_horse_12">
													{{ horse_pedigree.pedigree_horse_12.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_12 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_12">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="2">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_6.horse_id }}" v-if="horse_pedigree.pedigree_horse_6">
													{{ horse_pedigree.pedigree_horse_6.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_6 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_6">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_13.horse_id }}" v-if="horse_pedigree.pedigree_horse_13">
													{{ horse_pedigree.pedigree_horse_13.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_13 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_13">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="1">
											<span v-if="!edit_pedigree_bool">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse_pedigree.pedigree_horse_14.horse_id }}" v-if="horse_pedigree.pedigree_horse_14">
													{{ horse_pedigree.pedigree_horse_14.horse_nom }}
												</router-link>
												<span v-else>{{ horse_pedigree.pedigree_nom_14 }}</span>
											</span> 
											<input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_14">
										</td>
									</tr>
								</tbody>
							</table>
							<a href="#" @click.prevent="edit_pedigree" v-if="display_pedigree && !edit_pedigree_bool" class="float-right">{{ $t('horse.modifier_pedigree') }}</a>
							<a href="#" @click.prevent="valid_pedigree" v-if="display_pedigree && edit_pedigree_bool" class="float-right">{{ $t('horse.valider_pedigree') }}</a>
						</div>
					</div>
				</div>

				<ModalConfirm
					ref="confirm_sire"
					id="confirm_sire_c"
					:icon="['far', 'save']"
					:icon_accept="['fal', 'level-down']"
					:text_title="$t('modal.horse.validation_sire')"
					:text_question="text_question"
					:text_button_ok="$t('modal.general.sauvegarder')"
					:text_button_accept="$t('modal.general.ok')"
					:text_button_cancel="$t('modal.general.annuler')"
					:callback_validation="confirmSwitchSaveSireModal"
					:callback_cancel="cancelSwitchSireModal"
					:processing="processing_sire"
				/>

				<b-modal ref="pension_cloture" hide-footer>
	                <template v-slot:modal-title>
	                	{{ $t("acte.trigger.declencher.pension_cloture") }}
	                </template>

	                <div class="form-group">
                        <label for="date_cloture" class="col-form-label">{{ $t("horse.date_cloture") }}</label>
                        <e-datepicker id="date_cloture" v-model="date_pension"></e-datepicker>
                    </div>

	                <b-button class="mt-3 btn-block rounded-pill" block @click.prevent="savePension" variant="primary">{{ $t("action.cloturer") }}</b-button>
	            </b-modal>

				<b-form-file
					v-show="false"
					v-model="pedigree_file"
					@change="edited_pedigree_file = true"
					accept="application/pdf"
					:state="Boolean(pedigree_file)"
					:placeholder="$t('ordonnance.choisir_document')"
					:drop-placeholder="$t('fichier.drop_file')+'...'"
					ref="fileForm"
				></b-form-file>
			</div>
		</div>

		<b-modal ref="modalRapprocherDps" hide-footer>
			<template v-slot:modal-title>
				{{ $t('horse.rapprocher_cheval') }}
			</template>

			<div v-if="!modal_rapprocher_dps_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_rapprocher_dps_loaded">
                <template v-if="!dps.length">
                    {{ $t('gynecologie.aucune_donnee_dps_saillie_ws') }}
                 </template>
                 <template v-else>
                    <div v-if="error_dps" class="alert alert-warning">
                        {{ $t('gynecologie.erreur_dps_saillie_ws') }}
                    </div>

                    <div class="box my-3" v-for="data in dps" v-bind:key="data.monteSaillie">
                        {{ $t('horse.etalon') }} : <b>{{ data.etalonNom }}</b>
                        <br>
						{{ $t('monte.jument') }} : <b>{{ data.jumentNom }}</b>
                        <br>
                        {{ $t('monte.porteuse') }} : <b>{{ data.porteuseNom }}</b>
                        <br>
                        {{ $t('monte.date_premier_saut') }} : <b>{{ data.monteDatePremierSaut_format }}</b>
                        <br>
                        {{ $t('compte.datenaissance') }} : <b>{{ data.produitDateNaiss_format }}</b>
                        <br>
                        {{ $t('horse.nom_naissance') }} : <b>{{ data.produitNom }}</b>
                        <br>
                        {{ $t('horse.nom_retenue') }} : <b>{{ data.nomRetenu }}</b>
                        <br>
						{{ $t('horse.numero_sire') }} : <b>{{ data.sire }} {{ data.key }}</b>

                        <div class="text-center mt-3">
                            <b-form-radio name="dps_selected" :value="data.monteSaillie" v-model="dps_selected"></b-form-radio>
                        </div>
                    </div>
    
                    <div class="text-center mt-3">
                        <b-button variant="primary" :disabled="loading_rapprocher_dps" @click="rapprocher_dps()">
                            <font-awesome-icon v-if="loading_rapprocher_dps" :icon="['fas', 'spinner']" pulse />
                            {{ $t('global.rapprocher') }}
                        </b-button>
                    </div>
                </template>
			</div>
		</b-modal>
	</div>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import HorseMixin from "@/mixins/Horse.js"
	import GynecologieMixin from "@/mixins/Gynecologie.js"
	import Tools from "@/mixins/Tools.js"

	export default {
		name: 'InformationsHorse',
		mixins: [HorseMixin, GynecologieMixin, Tools],
		props:[
			'horse_id'
		],
		components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			HorseSexe: () => import('GroomyRoot/components/Horse/HorseSexe'),
			HorseRobe: () => import('@/components/Horse/HorseRobe'),
			HorseCategorie: () => import('GroomyRoot/components/Horse/HorseCategorie'),
			HorseTranspondeur: () => import('GroomyRoot/components/Horse/HorseTranspondeur'),
			HorseTaille: () => import('GroomyRoot/components/Horse/HorseTaille'),
			HorseInterne: () => import('GroomyRoot/components/Horse/HorseInterne'),
			HorseRace: () => import('@/components/Horse/HorseRace'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
			SearchInputPedigree : () => import('@/components/Inputs/SearchInputPedigree'),
			ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
			ModalSearchWeatherbys : () => import('@/components/Modals/ModalSearchWeatherbys')
		},
		data (){
			return {
				horse: null,
				horse_save: null,
				horse_pedigree: null,
				has_pedigree: false,
				saveButton: false,
				robe: null,
				race: null,
				horse_sire_cle: null,
				horse_ue: null,
				sexe: null,
				update_button_type: '',
				editable: false,
				readonly: true, // si le cheval a un SIRE ou UELN
				readonly_death: true, // si le cheval a une date de décès et un numéro SIRE ou UELN
				display_pedigree: false,
				edit_pedigree_bool: false,
				processing_sire: false,
				pedigree_link_working: false,
				pedigree_file: null,
				ue_state: 'initialized',
				tab_trad_manual_add: [
					'IK',
					'IF',
					'NOR',
				],
				sire_working: false,
				horse_datenaissance: null,
				horse_datemort: null,
				text_ue_dropdown: this.getTrad('horse.numero_ueln'),
				disabled_ueln: false,
				disabled_uesa: false,
				edited_pedigree_file: false,
				date_pension: new Date(),
				text_question: this.getTrad("modal.horse.text_validation_sire"),
				processing: false,
				limit_commentaire: 191,
		        error_commentaire: false,
				mask_ifce: false,
				horse_francise: true,
                loading_rapprocher_dps: false,
				modal_rapprocher_dps_loaded: false,
                error_dps: false,
                dps: null,
				dps_selected: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.horse = await this.getHorseById(this.horse_id)
				this.horse.horse_stallion = (this.horse.horse_stallion === 1 || this.horse.horse_stallion === true)
				this.horse_save = this.deppCloneObj(this.horse)
				this.horse_sire_cle = this.horse.horse_sire + ' ' + this.horse.horse_cle

				this.mask_ifce = this.getConfigIfce()

				if(this.horse.horse_sire == null || this.horse.horse_sire == "") {
					this.horse.horse_sire = ''
					this.horse.horse_cle = ''
					this.horse_save.horse_sire = ''
					this.horse_save.horse_cle = ''
					this.horse_sire_cle = null
					this.editable = true
					this.readonly = false 
					this.readonly_death = false
				}

				if(this.horse.horse_datemort) {
					this.horse_datemort = this.formatDate(this.horse.horse_datemort)
					if(this.horse.horse_sire == null || this.horse.horse_sire == "") {
						this.readonly_death = false
					}
				}
				else {
					this.readonly_death = false
				}

				if(this.horse.horse_datenaissance) {
					this.horse_datenaissance = this.formatDate(this.horse.horse_datenaissance)
				}

				await this.loadPedigree()

				if(this.horse.horse_sire == null || this.horse.horse_sire == "") {
					if(this.horse.horse_sireencode && this.horse.horse_sireencode.includes('_')){
						this.horse_francise = false
					}
					if(this.show_actions) this.display_pedigree = true
				}

				if(this.horse.horse_ueln) {
					this.horse_ue = this.horse.horse_ueln
					this.disabled_uesa = true
				}
				else if(this.horse.horse_uesa) {
					this.horse_ue = this.horse.horse_uesa
					this.text_ue_dropdown = this.getTrad('horse.numero_uesa')
					this.disabled_ueln = true
				}

				if(this.horse.horse_sire == "" && this.horse_pedigree.pedigree_sireencode_2 && window.navigator.onLine) {
					this.dps_selected = null
					const dps = await this.getDpsByChildren(this.horse_id)
					if(dps && dps.length > 0) {
						this.modal_rapprocher_dps_loaded = false
						if(dps.length == 1) {
							this.dps_selected = dps[0].monteSaillie
						}

						this.dps = dps
						this.$refs['modalRapprocherDps'].show()
						this.modal_rapprocher_dps_loaded = true
					}
				}
			},
			async loadPedigree() {
				this.horse_pedigree = await this.getHorsePedigree(this.horse_id)

				if (!this.horse_pedigree) {
					return
				}
				const pedigree_length = 14

				for(let i=1; i<pedigree_length+1; i++) {
					const pedigree_key = `pedigree_nom_${i}`

					if(!this.horse_pedigree[pedigree_key]) 
						this.horse_pedigree[pedigree_key] = 'NC'
					else {
						this.has_pedigree = true
					}
				}
			},
			changeRobe(evt) {
				this.robe = evt
				this.horse.horse_robe = evt
				this.showSaveButton()
			},
			changeRace(evt) {
				this.race = evt
				this.horse.horse_race = evt
				this.showSaveButton()
			},
			changeSexe(evt) {
				this.sexe = evt
				this.horse.horse_sexe = evt
				this.showSaveButton()
			},
			changeTaille(evt) {
				this.taille = evt
				this.horse.horse_taille = evt
				this.showSaveButton()
			},
			changeCountry(evt) {
				this.horse.horse_country_code = evt
				this.showSaveButton()
			},
			changeCaVisibility() {
				this.switchHorseCaVisibility(this.horse_id, this.horse.horse_display_ca)
			},
			showSaveButton() {
				this.saveButton = true
			},
			hideSaveButton() {
				this.saveButton = false
				this.update_button_type = ''
			},
			async saveInfo() {
				let status = false

				if(this.editable) {
					let variables = {}

					if(this.sexe != null){
						variables.horse_sexe = this.sexe
					}

					if(this.robe != null){
						variables.horse_robe = this.robe
					}

					if(this.race != null){
						variables.horse_race = this.race
					}

					if(this.horse.horse_nom != this.horse_save.horse_nom){
						variables.horse_nom = this.horse.horse_nom
					}

					if(this.horse.horse_alias != this.horse_save.horse_alias){
						variables.horse_alias = this.horse.horse_alias
					}

					if(this.horse.horse_datenaissance != this.horse_save.horse_datenaissance){
						variables.horse_datenaissance = this.horse.horse_datenaissance
					}

					if(this.horse.horse_taille != this.horse_save.horse_taille){
						variables.horse_taille = this.horse.horse_taille
					}

					if(this.horse.horse_interne != this.horse_save.horse_interne){
						variables.horse_interne = this.horse.horse_interne
					}

					if(this.horse.horse_transpondeur != this.horse_save.horse_transpondeur){
						variables.horse_transpondeur = this.horse.horse_transpondeur
					}

					if(this.horse.horse_passeport != this.horse_save.horse_passeport){
						variables.horse_passeport = this.horse.horse_passeport
					}

					if(this.horse.horse_stallion != this.horse_save.horse_stallion){
						variables.horse_stallion = this.horse.horse_stallion ? 1 : 0
					}

					if(this.horse.horse_country_code != this.horse_save.horse_country_code){
						variables.horse_country = this.horse.horse_country_code
					}

					if(this.horse.horse_datemort != this.horse_save.horse_datemort){
						variables.horse_datemort = this.horse.horse_datemort
						if(!this.horse.horse_mort){
							variables.horse_mort = 1
							this.date_pension = this.horse.horse_datemort
							this.$refs.pension_cloture.show()

							if(this.$store.state.userAccess.hasBreedingAccess && this.horse.horse_sexe == "F") {
								this.updateMareStatusDead(this.horse_id, this.horse.horse_datemort)
							}
						}

						if(this.horse.horse_mort && !this.horse.horse_datemort) {
							variables.horse_mort = 0
						}
					}

					if(this.horse.horse_localisationlivret != this.horse_save.horse_localisationlivret){
						variables.horse_localisationlivret = this.horse.horse_localisationlivret
					}

					if(this.horse.horse_carte != this.horse_save.horse_carte){
						variables.horse_carte = this.horse.horse_carte
					}

					if(this.horse.horse_commentaire != this.horse_save.horse_commentaire){
						variables.horse_commentaire = this.horse.horse_commentaire
					}

					status = true
					if(Object.keys(variables).length > 0) {
						status = await this.updateHorse(this.horse_id, variables)
					}

				} else if(this.editable == false) {
					if(this.update_button_type == 'horse_taille') {
						status = await this.updateHorseTaille(this.horse_id, this.horse.horse_taille)
					}
					if(this.horse.horse_datemort != this.horse_save.horse_datemort){
						this.date_pension = this.horse.horse_datemort
						this.deleteHorseResidence(this.horse.horse_id)
						this.$refs.pension_cloture.show()

						if(this.$store.state.userAccess.hasBreedingAccess && this.horse.horse_sexe == "F") {
							this.updateMareStatusDead(this.horse_id, this.horse.horse_datemort)
						}
					}
					status = await this.updateHorseType(this.horse_id, this.sexe, this.robe, this.horse.horse_interne, (this.horse.horse_stallion == true ? 1 : 0), this.horse.horse_country_code, this.horse.horse_datemort, this.horse.horse_mort, this.horse.horse_localisationlivret, this.horse.horse_carte, this.horse.horse_commentaire, this.horse.horse_transpondeur, this.horse.horse_nom, this.horse.horse_alias, this.horse.horse_passeport)
				}

				// Liens connexes
				if(this.horse.horse_francegalop != this.horse_save.horse_francegalop){
					status &= await this.updateHorse(this.horse_id, { horse_francegalop: this.horse.horse_francegalop })
				}

				let params = {
					title: this.getTrad('global.information'),
					body: this.getTrad('toast.info_modif_succes'),
					type: 'success'
				}

				// Si on a eu une erreur
				if(!status) {
					params.body = this.getTrad('toast.info_modif_failed')
					params.type = 'danger'
				}
				else {
					// Je veux mettre a jour le header
            		EventBus.$emit('HorseFiche::refreshHorse', {})
            		this.horse_save = this.deppCloneObj(this.horse)
				}

				EventBus.$emit('Header::displayToast', params)
				this.hideSaveButton()
			},
			cancelInfo() {
                const restored_horse = this.deppCloneObj(this.horse_save)
                restored_horse.horse_datenaissance = new Date(restored_horse.horse_datenaissance)
                
                this.horse = restored_horse
				this.hideSaveButton()
			},
			async confirm_sire() {
				if(this.processing == false) {
					this.processing = true
					let sire = this.horse_sire_cle.substr(0, 8)
					await this.getHorseAndParents(sire)
					.then(result => {
						if(result) {
							this.text_question = this.getTrad("modal.horse.text_validation_sire_with_names", { horse_nom: result.horse_nom, horse_mere: result.horse_mere, horse_pere: result.horse_pere })
						}
						this.processing = false
					})
					this.$refs.confirm_sire.openModal()
				}
			},
			weatherbysUpdated(){
				EventBus.$emit('HorseFiche::refreshHorse', {})
				this.init_component()
			},
			cancelSwitchSireModal() {
				this.$refs.confirm_sire.closeModal()
			},
			async confirmSwitchSaveSireModal() {
				if(this.processing_sire == false) {
					this.processing_sire = true

					let sire = this.horse_sire_cle.substr(0, 8)
					let cle = this.horse_sire_cle.substr(9, 1)

					let horse_exists = await this.sireAlreadyExists(sire, cle)

					if(!horse_exists) {
						let input = sire + cle

						let result = await this.search_sire_horse_manual(input, this.horse.horse_id)
						if(result.code_retour === 0) {
							await this.$sync.force(true)
							EventBus.$emit('HorseFiche::refreshHorse', {})
							this.$refs.confirm_sire.closeModal()
							this.init_component()

							this.editable = false
							this.readonly = true
						}
						else {
							let error_message = ""

							if(this.tab_trad_manual_add.indexOf(result) > -1) {
								error_message = this.getTrad("horse.introuvable")
							}
							error_message = this.getTrad("error."+(result=="" ? "UKE" : result))
							this.failureToast(error_message, true)
						}
					}
					else {
						this.failureToast(this.getTrad("horse.already_exists"), true)
					}
					this.processing_sire = false
				}
			},
			edit_pedigree() {
				this.edit_pedigree_bool = true
			},
			async link_pedigree_file() {
				if(this.edited_pedigree_file) {
					this.pedigree_link_working = true
					await this.linkPedigreeFile(this.horse.horse_id, this.horse_pedigree.pedigree_id, this.pedigree_file)
					
					this.pedigree_file = null
					this.edited_pedigree_file  = false
					this.pedigree_link_working = false
					this.successToast()
				}
				else {
					this.$refs.fileForm.$el.children[0].click()
				}
			},
			async valid_pedigree() {
				if(this.horse_pedigree.pedigree_sireencode_1) {
					let pere = await this.getHorseBySireEncode(this.horse_pedigree.pedigree_sireencode_1)
					if(pere) {
						let pere_pedigree = await this.getHorsePedigree(pere.horse_id)

						this.horse_pedigree.pedigree_nom_3 = pere_pedigree.pedigree_nom_1 ? pere_pedigree.pedigree_nom_1 : "NC"
						this.horse_pedigree.pedigree_sireencode_3 = pere_pedigree.pedigree_sireencode_1 ? pere_pedigree.pedigree_sireencode_1 : null
						this.horse_pedigree.pedigree_nom_4 = pere_pedigree.pedigree_nom_2 ? pere_pedigree.pedigree_nom_2 : "NC"
						this.horse_pedigree.pedigree_sireencode_4 = pere_pedigree.pedigree_sireencode_3 ? pere_pedigree.pedigree_sireencode_2 : null
						this.horse_pedigree.pedigree_nom_7 = pere_pedigree.pedigree_nom_3 ? pere_pedigree.pedigree_nom_3 : "NC"
						this.horse_pedigree.pedigree_sireencode_7 = pere_pedigree.pedigree_sireencode_3 ? pere_pedigree.pedigree_sireencode_3 : null
						this.horse_pedigree.pedigree_nom_8 = pere_pedigree.pedigree_nom_4 ? pere_pedigree.pedigree_nom_4 : "NC"
						this.horse_pedigree.pedigree_sireencode_8 = pere_pedigree.pedigree_sireencode_4 ? pere_pedigree.pedigree_sireencode_4 : null
						this.horse_pedigree.pedigree_nom_9 = pere_pedigree.pedigree_nom_5 ? pere_pedigree.pedigree_nom_5 : "NC"
						this.horse_pedigree.pedigree_sireencode_9 = pere_pedigree.pedigree_sireencode_5 ? pere_pedigree.pedigree_sireencode_5 : null
						this.horse_pedigree.pedigree_nom_10 = pere_pedigree.pedigree_nom_6 ? pere_pedigree.pedigree_nom_6 : "NC"
						this.horse_pedigree.pedigree_sireencode_10 = pere_pedigree.pedigree_sireencode_6 ? pere_pedigree.pedigree_sireencode_6 : null
					}
				}
				if(this.horse_pedigree.pedigree_sireencode_2) {
					let mere = await this.getHorseBySireEncode(this.horse_pedigree.pedigree_sireencode_2)
					if(mere) {
						let mere_pedigree = await this.getHorsePedigree(mere.horse_id)

						this.horse_pedigree.pedigree_nom_5 = mere_pedigree.pedigree_nom_1 ? mere_pedigree.pedigree_nom_1 : "NC"
						this.horse_pedigree.pedigree_sireencode_5 = mere_pedigree.pedigree_sireencode_1 ? mere_pedigree.pedigree_sireencode_1 : null
						this.horse_pedigree.pedigree_nom_6 = mere_pedigree.pedigree_nom_2 ? mere_pedigree.pedigree_nom_2 : "NC"
						this.horse_pedigree.pedigree_sireencode_6 = mere_pedigree.pedigree_sireencode_2 ? mere_pedigree.pedigree_sireencode_2 : null
						this.horse_pedigree.pedigree_nom_11 = mere_pedigree.pedigree_nom_3 ? mere_pedigree.pedigree_nom_3 : "NC"
						this.horse_pedigree.pedigree_sireencode_11 = mere_pedigree.pedigree_sireencode_3 ? mere_pedigree.pedigree_sireencode_3 : null
						this.horse_pedigree.pedigree_nom_12 = mere_pedigree.pedigree_nom_4 ? mere_pedigree.pedigree_nom_4 : "NC"
						this.horse_pedigree.pedigree_sireencode_12 = mere_pedigree.pedigree_sireencode_4 ? mere_pedigree.pedigree_sireencode_4 : null
						this.horse_pedigree.pedigree_nom_13 = mere_pedigree.pedigree_nom_5 ? mere_pedigree.pedigree_nom_5 : "NC"
						this.horse_pedigree.pedigree_sireencode_13 = mere_pedigree.pedigree_sireencode_5 ? mere_pedigree.pedigree_sireencode_5 : null
						this.horse_pedigree.pedigree_nom_14 = mere_pedigree.pedigree_nom_6 ? mere_pedigree.pedigree_nom_6 : "NC"
						this.horse_pedigree.pedigree_sireencode_14 = mere_pedigree.pedigree_sireencode_6 ? mere_pedigree.pedigree_sireencode_6 : null
					}
				}

				let response = await this.update_pedigree_horse_manual(this.horse.horse_id, this.horse_pedigree)

				if(response != null) {
					this.edit_pedigree_bool = false
					let params = {
						title: this.getTrad('global.information'),
						body: this.getTrad('toast.info_modif_succes'),
						type: 'success'
					}
					EventBus.$emit('Header::displayToast', params)

					this.loadPedigree()
				}
			},
			async refresh_sire_infos() {
				this.sire_working = true
				try {
					let temp_deces = this.horse.horse_datemort
					await this.refreshSireInfos(this.horse.horse_id)
					await this.init_component()
					if(!temp_deces && this.horse_datemort) {
						this.$refs.pension_cloture.show()
					}
				} finally {
					this.sire_working = false
				}
			},
			async saveHorseUe() {
				this.ue_state = 'saving'
				if(this.text_ue_dropdown == this.getTrad('horse.numero_ueln')) {
					await this.updateHorse(this.horse.horse_id, {
						horse_ueln: this.horse_ue
					})
				}
				else if(this.text_ue_dropdown == this.getTrad('horse.numero_uesa')) {
					await this.updateHorse(this.horse.horse_id, {
						horse_uesa: this.horse_ue
					})
				}
				this.ue_state = 'saved'
			},
			async savePension() {
				this.$refs.pension_cloture.hide()
				this.closePensionAndAddMouvementMort(this.horse_id, this.date_pension, this.horse.horse_datemort)
			},
			print_infos() {
				this.$refs.modal_print_infos_horse.openModal([this.horse_id])
			},
			merge_horse() {
				this.$router.push({ name: 'mergeHorse', params: { horse_id: this.horse_id }})
			},
			async unarchive_horse(){
				await this.archiveOrUnarchiveHorse(this.horse_id)
				this.$router.replace({ name: 'horseListe' })
			},
			liveCountDown() {
				if(this.horse.horse_commentaire) {
					const totalRemainCount = this.limit_commentaire - this.horse.horse_commentaire.length
					this.error_commentaire = totalRemainCount < 0
					this.showSaveButton()
				}
			},
			refreshAfterChangeMicroship() {
				EventBus.$emit('HorseFiche::refreshHorse', {})
				this.init_component()
				this.editable = false
				this.readonly = true
			},
			async rapprocher_dps() {
				this.loading_rapprocher_dps = true
                const checked = this.dps.filter(_dps => _dps.monteSaillie == this.dps_selected)
				if(checked && checked.length > 0) {
					const variables = {
						horse_sire: checked[0].sire,
						horse_cle: checked[0].key
					}

					const status = await this.updateHorse(this.horse_id, variables)
					if(status) {
						this.dps_selected = null
                		this.loading_rapprocher_dps = false
                		this.$refs['modalRapprocherDps'].hide()
						this.init_component()
						this.editable = false
						this.readonly = true
					}
					else {
						let params = {
							title: this.getTrad('global.information'),
							body: this.getTrad('toast.info_modif_failed'),
							type: 'danger'
						}
						EventBus.$emit('Header::displayToast', params)
                		this.loading_rapprocher_dps = false
					}
				}
				else {
                	this.loading_rapprocher_dps = false
				}
			},
			edit_weatherbys(){
				this.$refs.modal_search_weatherbys.openModal();
			}
		},
		computed:{
			isOkSire(){
				if(this.horse_sire_cle && this.horse_sire_cle.length == 10) {
					return true
				}
				return false
			},
			show_actions() { // si la fiche cheval est en lecture seul
				return !this.$store.state.horseFicheReadonly
			},
			hasDedicatedClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			},
			hasWeatherbys(){
				return ((this.horse.horse_race == 'PS' || this.horse.horse_race == 'WB') && this.getConfig('api_config').find(conf => conf.apiconfig_type == "WTHBS") )
			}
		},
		watch: {
			'horse.horse_taille' (val, prev) {
				if(prev === undefined || prev === null) return

				this.update_button_type = 'horse_taille'
				this.showSaveButton()
			},
			horse: {
				handler(val){
					if(JSON.stringify(val) != JSON.stringify(this.horse_save)) {
						this.showSaveButton()
						this.liveCountDown()
					}
					else {
						this.hideSaveButton()
					}
				},
				deep: true
			},
			'horse_id' (val) {
				this.init_component()	
			},
			horse_ue() {
				if (this.horse_ue !== this.horse.horse_ueln && this.text_ue_dropdown == this.getTrad('horse.numero_ueln')) {
					this.ue_state = 'editing'
				}
				else if (this.horse_ue !== this.horse.horse_uesa && this.text_ue_dropdown == this.getTrad('horse.numero_uesa')) {
					this.ue_state = 'editing'
				}
			}
		}
	}
</script>